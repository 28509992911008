import { BaseModel, Default, EndOfDate, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import {
  EBusinessDirector,
  EBuyerPropertyType,
  EBuyerType,
  ECitizen,
  EGender,
  ENumberOf,
  EProjectPropertyType,
  EProjectTransactionPropertyType,
  EPurpose,
  ERebateBy,
} from '../enums';
import { IProjectTransactionBuyerDto } from '../interfaces';
import { NationalityModel } from '../models';
import { AddressBuyerDto } from './address.dto';
import { AbstractListFilters } from '@shared/core';

export class ProjectTransactionTypeDto extends BaseModel {
  @Expose()
  type!: string;

  @Expose()
  @Type(() => Number)
  specifyAmount!: number;

  @Expose()
  absorbBy!: string;

  @Expose()
  @Type(() => Number)
  fromAgent!: number;

  @Expose()
  @Type(() => Number)
  fromCompany!: number;

  @Expose()
  rebateBy!: ERebateBy;

  @Expose()
  currency!: string;

  @Expose()
  @Type(() => Number)
  foreignAmount!: number;

  @Expose()
  foreignCurrency!: string;
}

export class TransactedPercentItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  value!: number;

  @Expose()
  @Type(() => Number)
  percent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };
}

export class TransactedPercentDto extends BaseModel {
  @Expose()
  @Type(() => TransactedPercentItemDto)
  cashAdvance!: TransactedPercentItemDto;

  @Expose()
  @Type(() => TransactedPercentItemDto)
  discount!: TransactedPercentItemDto;

  @Expose()
  @Type(() => TransactedPercentItemDto)
  powerDiscount!: TransactedPercentItemDto;

  @Expose()
  @Type(() => TransactedPercentItemDto)
  incentive!: TransactedPercentItemDto;

  @Expose()
  @Type(() => TransactedPercentItemDto)
  buyerRebate!: TransactedPercentItemDto;
}

export class ProjectTransactionRelatedAgentDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  teamType!: string;

  @Expose()
  @Type(() => Number)
  teamId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Default({})
  @Type(() => TransactedPercentDto)
  transacted!: TransactedPercentDto;

  @Expose()
  @Type(() => Boolean)
  isCobroke!: boolean;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Type(() => String)
  grouping!: string;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  attrs!: {
    [key: string]: any;
  };

}

export class ProjectTransactionRelatedInternalCobrokeDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Default({})
  @Type(() => TransactedPercentDto)
  transacted!: TransactedPercentDto;

  @Expose()
  type!: string;

  @Expose()
  teamId?: number;

  @Expose()
  teamType?: string;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Type(() => String)
  grouping!: string;

  @Expose()
  attrs!: {
    [key: string]: any;
  };

  @Expose()
  @Type(() => String)
  appTypeCode!: string;
}

export class ProjectTransactionRelatedExternalCobrokeDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({})
  @Type(() => TransactedPercentDto)
  transacted!: TransactedPercentDto;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  attrs!: {
    [key: string]: any;
  };
}

export class ProjectTransactionRelatedPartyDto extends BaseModel {
  @Expose()
  @Type(() => ProjectTransactionRelatedAgentDto)
  main!: ProjectTransactionRelatedAgentDto;

  @Expose()
  @Type(() => ProjectTransactionLeadersDto)
  leaders!: ProjectTransactionLeadersDto[];

  @Expose()
  @Type(() => ProjectTransactionRelatedInternalCobrokeDto)
  internals!: ProjectTransactionRelatedInternalCobrokeDto[];

  @Expose()
  @Type(() => ProjectTransactionRelatedExternalCobrokeDto)
  externals!: ProjectTransactionRelatedExternalCobrokeDto[];
}

export class ProjectTransactionBonusSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBonusDto)
  data!: ProjectTransactionBonusDto[];
}

export class ProjectTransactionOverridingSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionOverridingDto)
  data!: ProjectTransactionOverridingDto[];
}

export class ProjectTransactionPoolSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionPoolDto)
  data!: ProjectTransactionPoolDto[];
}

export class ProjectTransactionBuyerSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBuyerDto)
  data!: ProjectTransactionBuyerDto[];
}

export class ProjectTransactionBdsSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBdsDto)
  data!: ProjectTransactionBdsDto[];
}

export class ProjectTransactionLeadersSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionLeadersDto)
  data!: ProjectTransactionLeadersDto[];
}

export class ProjectTransactionCreateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  unitId!: number;

  @Expose()
  optionNo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  eotDate!: string;

  @Expose()
  // propertyType!: EProjectTransactionPropertyType;
  propertyType!: EProjectPropertyType;

  @Expose()
  landAreaSqft!: number;

  @Expose()
  landAreaSqm!: number;

  @Expose()
  @Type(() => Number)
  projectCommissionId!: number;

  @Expose()
  @Type(() => Number)
  projectCommInternationalId!: number;

  @Expose()
  @Type(() => ProjectTransactionTypeDto)
  transactedTypes!: ProjectTransactionTypeDto[];

  @Expose()
  @Type(() => ProjectTransactionTypeDto)
  hiTransactedTypes!: ProjectTransactionTypeDto[];

  @Expose()
  @Type(() => Number)
  transactedPrice!: number;

  @Expose()
  @Type(() => ProjectTransactionRelatedPartyDto)
  relatedAgent!: ProjectTransactionRelatedPartyDto;

  @Expose()
  remarks!: string;

  @Expose()
  buyer!: {
    remark: string;
  };

  @Expose()
  @Type(() => Number)
  originalTransactedPrice?: number;

  @Expose()
  originalCurrencyId?: number;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBdsSetUpDto || null)
  bds!: ProjectTransactionBdsSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBonusSetUpDto || null)
  bonuses!: ProjectTransactionBonusSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionOverridingSetUpDto || null)
  overridings!: ProjectTransactionOverridingSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionPoolSetUpDto || null)
  pools!: ProjectTransactionPoolSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBuyerSetUpDto || null)
  buyers!: ProjectTransactionBuyerSetUpDto | null;

  @Expose()
  @Default({})
  metadata!: {
    admin: string;
    isFirstTimeSubmitted: boolean;
  };
}

export class ProjectTransactionDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  unitId!: number;

  @Expose({ groups: ['full', 'general'] })
  optionNo!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  eotDate!: string;

  @Expose({ groups: ['full', 'general'] })
  propertyType!: EProjectTransactionPropertyType;

  @Expose({ groups: ['full', 'general'] })
  landAreaSqft!: number;

  @Expose({ groups: ['full', 'general'] })
  landAreaSqm!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  projectCommissionId!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  projectCommInternationalId!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => ProjectTransactionTypeDto)
  transactedTypes!: ProjectTransactionTypeDto[];

  @Expose({ groups: ['full', 'general'] })
  @Type(() => ProjectTransactionTypeDto)
  hiTransactedTypes!: ProjectTransactionTypeDto[];

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Number)
  transactedPrice!: number;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => ProjectTransactionRelatedPartyDto)
  relatedAgent!: ProjectTransactionRelatedPartyDto;

  @Expose({ groups: ['full', 'general', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full', 'general'] })
  buyer!: {
    remark: string;
  };

  @Expose()
  @Type(() => Number)
  originalTransactedPrice?: number;

  @Expose()
  originalCurrencyId?: number;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  reissueDate?: string;

  @Expose({ groups: ['full', 'general'] })
  @Default(false)
  @Type(() => Boolean)
  isReissue?: boolean;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBdsSetUpDto || null)
  bds!: ProjectTransactionBdsSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBonusSetUpDto || null)
  bonuses!: ProjectTransactionBonusSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionOverridingSetUpDto || null)
  overridings!: ProjectTransactionOverridingSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionPoolSetUpDto || null)
  pools!: ProjectTransactionPoolSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBuyerSetUpDto || null)
  buyers!: ProjectTransactionBuyerSetUpDto | null;

  @Expose({ groups: ['full'] })
  metadata!: {
    admin: string;
    isFirstTimeSubmitted: boolean;
  };
}

export class ProjectTransactionUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'general' | 'status' | 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return ProjectTransactionDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: ProjectTransactionDataUpdateDto;
}

export class ProjectTransactionBonusDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;
}

export class ProjectTransactionOverridingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;
}

export class ProjectTransactionPoolDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;
}

export class ProjectTransactionBuyerDto extends BaseModel implements IProjectTransactionBuyerDto {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: EBuyerType;

  @Expose()
  gender!: EGender;

  @Expose()
  @Type(() => Number)
  maritalId!: number;

  @Expose()
  propertyType!: EBuyerPropertyType;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  nricNo!: string;

  @Expose()
  @Type(() => Number)
  yearOfBirth!: number;

  @Expose()
  @Type(() => AddressBuyerDto)
  address!: AddressBuyerDto;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  email!: string;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  @Type(() => Number)
  nationalityId!: number;

  @Expose()
  @Type(() => NationalityModel)
  nationality!: NationalityModel;

  @Expose()
  @Type(() => Number)
  districtId!: number;

  @Expose()
  occupation!: string;

  @Expose()
  ownership!: ENumberOf;

  @Expose()
  @Type(() => Boolean)
  isHdbOwnership!: boolean;

  @Expose()
  purposeOfPurchase!: EPurpose;
}

export class ProjectTransactionBdsDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  splitMatrixId!: number;

  @Expose()
  @Default(EBusinessDirector.BUSINESS_DIRECTOR)
  type!: `${EBusinessDirector}`;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedDirectPercent!: number;

  @Expose()
  @Type(() => TransactedPercentDto || null)
  transacted?: TransactedPercentDto | null;
}

export class ProjectTransactionLeadersDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Type(() => Number)
  teamId!: number;

  // @Expose()
  // @Type(() => Number)
  // teamType!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  isActive!: boolean;
}

export class ProjectTransactionFilters extends AbstractListFilters {
  @Expose()
  searchType!: string;

  @Expose()
  code!: string;

  @Expose()
  projectName!: string;

  @Expose()
  unitNo!: string;

  @Expose()
  projectCommission!: string;

  @Expose()
  remarks!: string;

  @Expose()
  agent!: string;

  @Expose()
  optionNo!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  optionDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  optionDateEnd!: string;

  @Expose()
  submittedBy!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  submittedDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  submittedDateEnd!: string;

  @Expose()
  status!: string;

  @Expose()
  companyIds!: number[];
}
